import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from "react-pdf";
import { useSwipeable } from "react-swipeable";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import NavBar from "../components/NavBar";
import { FaDownload, FaSearch } from "react-icons/fa";

const Catalogo = () => {
    const { t } = useTranslation();
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        "pdfjs-dist/build/pdf.worker.min.mjs",
        import.meta.url
    ).toString();

    const pdfFilePC = "/catalogos/SAVEENERGY_Catálogo_2025.pdf";
    const pdfFileMobile = "/catalogos/SAVEENERGY_Catálogo_2025_mobile.pdf";
    const suggestionsRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [searchQuery, setSearchQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [productTitles, setProductTitles] = useState([]);
    const [isSearchVisible, setSearchVisible] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setSuggestions([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            const script = document.createElement("script");
            script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
            script.async = true;
            script.onload = () => {
                const formInstance = new window.RDStationForms(
                    "baixar-catalogo-37b8ddb256147520bf29",
                    "UA-169207827-1"
                );

                formInstance.createForm();

                // Add a submit event listener
                const formElement = document.getElementById("baixar-catalogo-37b8ddb256147520bf29");
                if (formElement) {
                    formElement.addEventListener("submit", (event) => {
                        event.preventDefault(); // Prevent default form submission
                        handleDownload(); // Trigger PDF download
                    });
                }
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isModalOpen]);

    const onDocumentLoadSuccess = useCallback(
        async ({ numPages, _ }) => {
            setNumPages(numPages);

            const pdf = await pdfjs.getDocument(isMobile ? pdfFileMobile : pdfFilePC).promise;
            const textArray = [];

            for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
                const page = await pdf.getPage(pageNumber);
                const textContent = await page.getTextContent();
                const pageText = textContent.items.map((item) => item.str).join(" ");
                textArray.push({ pageNumber, text: pageText });
            }

            setProductTitles(textArray);
        },
        [isMobile, pdfFilePC, pdfFileMobile]
    );

    const handleDownload = () => {
        setModalOpen(false);
        const link = document.createElement("a");
        link.href = isMobile ? pdfFileMobile : pdfFilePC;
        link.download = isMobile ? "SAVEENERGY_Catálogo_2025_mobile.pdf" : "SAVEENERGY_Catálogo_2025.pdf";
        link.click();
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (!query) {
            setSuggestions([]);
            return;
        }

        const results = productTitles
            .filter((item) => item.text.toLowerCase().includes(query))
            .map((item) => ({
                page: item.pageNumber,
                text: `Page ${item.pageNumber}: ${item.text.substring(
                    item.text.toLowerCase().indexOf(query),
                    item.text.toLowerCase().indexOf(query) + 120
                )}...`,
            }));

        setSuggestions(results);
    };

    const goToPage = (page) => {
        setPageNumber(page);
        setSuggestions([]); // Ensure suggestions are cleared
    };

    const goToPreviousPage = () => {
        setPageNumber((prev) => Math.max(prev - 1, 1));
    };

    const goToNextPage = () => {
        setPageNumber((prev) => Math.min(prev + 1, numPages));
    };

    const handleSwipeLeft = () => {
        if (pageNumber < numPages) setPageNumber((prev) => prev + 1);
    };

    const handleSwipeRight = () => {
        if (pageNumber > 1) setPageNumber((prev) => prev - 1);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
    });

    const PAGE_WIDTH = isMobile ? 300 : 0; // Adjust width for mobile
    const PAGE_HEIGHT = isMobile ? undefined : 542; // Height is responsive for mobile
    const SCALE = isMobile ? 1.2 : 2; // Adjust scale for mobile

    const pagesToRender = useMemo(() => {
        if (numPages === null) return [];
        return [pageNumber]; // Always display only the current page
    }, [pageNumber, numPages]);

    const renderPage = useCallback(
        (pageNum) => (
            <Page
                key={pageNum}
                pageNumber={pageNum}
                width={PAGE_WIDTH ? PAGE_WIDTH * SCALE : undefined}
                height={PAGE_HEIGHT ? PAGE_HEIGHT * SCALE : undefined}
                renderAnnotationLayer={false}
                renderTextLayer={true}
            />
        ),
        [PAGE_WIDTH, PAGE_HEIGHT, SCALE]
    );

    const handleClickOutside = (e) => {
        if (e.target.id === "modal-background") {
            setModalOpen(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Catálogo Save Energy | Conheça Nossos Produtos</title>
                <meta name="description" content="Acesse o nosso catálogo completo de produtos e confira todas as informações técnicas detalhadas que você precisa. Acesse agora e descubra como nossos produtos podem impulsionar o sucesso." />
            </Helmet>
            <NavBar />
            <header className="bg-[#3c1053] py-4 text-white text-center">
                <div className="flex justify-between items-center mx-4 md:mx-16">
                    <h1 className="text-sm md:text-2xl font-bold">{t('catalog.title')}</h1>
                    <nav className="hidden md:block">
                        <ul className="flex space-x-4">
                            {[
                                { page: 4, label: "directional" },
                                { page: 12, label: "decorative" },
                                { page: 36, label: "external" },
                                { page: 39, label: "general" },
                                { page: 48, label: "embedded" },
                                { page: 62, label: "graphics" }
                            ].map((item, idx) => (
                                <li
                                    key={idx}
                                    onClick={() => goToPage(item.page)}
                                    className="cursor-pointer hover:text-purple-600"
                                >
                                    {t(`catalog.categories.${item.label}`)}
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="flex items-center">
                        <button onClick={() => setModalOpen(true)} className="hover:text-purple-600">
                            <FaDownload size={20} title={t('catalog.download')} />
                        </button>
                        <button onClick={() => setSearchVisible(!isSearchVisible)} className="hover:text-purple-600">
                            <FaSearch size={20} title={t('catalog.search')} />
                        </button>
                    </div>
                </div>
                {/* Mobile dropdown navigation */}
                {isMobile && (
                    <div className="relative px-4 mt-2">
                        <select
                            onChange={(e) => goToPage(Number(e.target.value))}
                            className="w-full px-2 py-1 border rounded-md text-gray-800 bg-white"
                        >
                            <option value="">{t('catalog.selectCategory')}</option>
                            {[
                                { page: 6, label: "directional" },
                                { page: 22, label: "decorative" },
                                { page: 71, label: "external" },
                                { page: 76, label: "general" },
                                { page: 95, label: "embedded" },
                                { page: 122, label: "graphics" }
                            ].map((item, idx) => (
                                <option key={idx} value={item.page}>
                                    {t(`catalog.categories.${item.label}`)}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {isSearchVisible && (
                    <div className="relative mt-4 w-2/3 mx-auto z-50" ref={suggestionsRef}>
                        <input
                            type="text"
                            placeholder={t('catalog.search')}
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className="border rounded-md p-2 w-full text-gray-800 bg-white"
                        />
                        {suggestions.length > 0 && (
                            <ul className="absolute left-0 w-full mt-1 bg-white text-black rounded-md shadow-lg z-50 text-left">
                                {suggestions.map((result, idx) => (
                                    <li
                                        key={idx}
                                        onClick={() => goToPage(result.page)}
                                        className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                                    >
                                        {result.text}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </header>

            <main
                {...(isMobile ? swipeHandlers : {})}
                className="relative flex flex-col items-center bg-gray-100 py-6 px-4"
            >
                <Document
                    file={isMobile ? pdfFileMobile : pdfFilePC}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={
                        <div className="text-center text-purple-900 font-bold text-lg">
                            {t('catalog.loading')} {/* Add translation key for "Loading PDF" */}
                        </div>
                    }
                >
                    <div className="relative flex items-center">
                        {!isMobile && (
                            <button
                                onClick={goToPreviousPage}
                                disabled={pageNumber <= 1}
                                className="fixed -left-16 bg-purple-900 text-white px-4 py-2 rounded-md disabled:bg-gray-400 z-50"
                                style={{ top: "50%", transform: "translateY(-50%)" }}
                            >
                                {t('catalog.previous')}
                            </button>
                        )}
                        <div className="flex">
                            {pagesToRender.map((p) => renderPage(p))}
                        </div>
                        {!isMobile && (
                            <button
                                onClick={goToNextPage}
                                disabled={pageNumber >= numPages}
                                className="fixed -right-16 bg-purple-900 text-white px-4 py-2 rounded-md disabled:bg-gray-400 z-50"
                                style={{ top: "50%", transform: "translateY(-50%)" }}
                            >
                                {t('catalog.next')}
                            </button>
                        )}
                    </div>
                </Document>
            </main>

            {isModalOpen && (
                <div
                    id="modal-background"
                    onClick={handleClickOutside}
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                >
                    <div
                        className="bg-white p-6 rounded-md shadow-lg text-center space-y-4"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            role="main"
                            id="baixar-catalogo-37b8ddb256147520bf29"
                            dangerouslySetInnerHTML={{ __html: "" }}
                        ></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Catalogo;
